import { Classes } from "@blueprintjs/core";
import { darken, rgba } from "polished";
import { createGlobalStyle } from "styled-components";

import {
  HEX_CUSTOM_BACKGROUND_REFRESH_TOAST_CLASS,
  HEX_CUSTOM_TOAST_CLASS,
} from "../../components/common/Toasts.js";
import { STATIC_ICON } from "../../components/icons/staticIcons.js";
import { buttonStyles } from "../../hex-components/HexButton";
import { popoverStyles } from "../../hex-components/HexPopover";

import { reset } from "./reset";
import { Theme } from "./theme";

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  ${reset}

  html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;

    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-family: ${({ theme }) => theme.fontFamily.DEFAULT};

    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};

    strong {
      font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
    }

    a {
      color: ${({ theme }) => theme.fontColor.LINK};
    }

    ${({ theme }) => theme.globalCSSVars}
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => rgba(theme.iconColor, 0.4)};
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 10px;

    /* Add a bit more border where the track border will be for accurate alignment */
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    &:vertical {
      border-left: 3px solid rgba(0, 0, 0, 0);
    }

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    &:horizontal {
      border-top: 3px solid rgba(0, 0, 0, 0);
    }

    &:hover {
      background-color: ${({ theme }) => rgba(theme.iconColor, 0.6)};
    }
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    &:horizontal {
      border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
    }
  }

  .${Classes.POPOVER} {
    ${popoverStyles}
  }

  /* It's hard to use custom components in BP for toasts, so just style ourselves */
  .${Classes.TOAST} {
    align-items: center;
    max-width: 650px;

    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
    box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};

    && a {
      ${({ theme }) => theme.resets.a};
    }

    .${Classes.BUTTON_GROUP}.${Classes.MINIMAL} {
      .${Classes.BUTTON} {
        ${buttonStyles}
        background: none !important;
        box-shadow: none !important;

        &:hover {
          background-color: ${({ theme }) => theme.hoverColor} !important;
        }
      }
    }

    /* Terrible CSS hack to make sure a toast we're using in one place
       uses primary styles for its action button but plain styles elsewhere.
       I'm truly sorry.
    */
    &.${HEX_CUSTOM_BACKGROUND_REFRESH_TOAST_CLASS} {
      && {
        padding: 4px;
      }
      .${Classes.BUTTON_GROUP}.${Classes.MINIMAL} {
        .${Classes.BUTTON}:first-child {
          &, .${Classes.ICON}.${Classes.ICON} svg {
            color: ${({ theme }) =>
              theme.button.default.primary.fontColor} !important;
          }
          margin: 0 10px;

          background-color: ${({ theme }) =>
            theme.button.default.primary.backgroundColor} !important;

          &:hover {
            background-color: ${({ theme }) =>
              darken(
                0.05,
                theme.button.default.primary.backgroundColor,
              )} !important;
          }

          &:active {
            background-color: ${({ theme }) =>
              darken(
                0.1,
                theme.button.default.primary.backgroundColor,
              )} !important;
          }
        }
        .${Classes.BUTTON}:last-child {
            &, .${Classes.ICON}.${Classes.ICON} svg {
              color: ${({ theme }) => theme.iconColor} !important;
            }
          }
      }
    }


    &:not(.${HEX_CUSTOM_TOAST_CLASS}) {
      box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};

      /* intent specific color */
      &.${Classes.INTENT_PRIMARY} {
        border: 1px solid ${({ theme }) => rgba(theme.intent.PRIMARY, 0.5)};
        background: ${({ theme }) => theme.CalloutOrToastBackground.primary};

        .${Classes.ICON}.${Classes.ICON} svg {
          color: ${({ theme }) => theme.intent.PRIMARY} !important;
        }
      }

      &.${Classes.INTENT_SUCCESS} {
        border: 1px solid ${({ theme }) => rgba(theme.intent.SUCCESS, 0.5)};
        background: ${({ theme }) => theme.CalloutOrToastBackground.success};

        .${Classes.ICON}.${Classes.ICON} svg {
          color: ${({ theme }) => theme.intent.SUCCESS} !important;
        }
      }

      &.${Classes.INTENT_WARNING} {
        border: 1px solid ${({ theme }) => rgba(theme.intent.WARNING, 0.5)};
        background: ${({ theme }) => theme.CalloutOrToastBackground.warning};

        .${Classes.ICON}.${Classes.ICON} svg {
          color: ${({ theme }) => theme.intent.WARNING} !important;
        }

      }

      &.${Classes.INTENT_DANGER} {
        border: 1px solid ${({ theme }) => rgba(theme.intent.DANGER, 0.5)};
        background: ${({ theme }) => theme.CalloutOrToastBackground.danger};

        .${Classes.ICON}.${Classes.ICON} svg {
          color: ${({ theme }) => theme.intent.DANGER} !important;
        }
      }

      /* Keep buttons void of intent */
      &, .${Classes.BUTTON},
      .${Classes.BUTTON}:hover {
        color: ${({ theme }) => theme.fontColor.DEFAULT} !important;

        .${Classes.ICON}.${Classes.ICON} svg {
          color: ${({ theme }) => theme.fontColor.DEFAULT} !important;
        }
      }

      /* Hide the bp svg, and then add our own svg code as a background image */
        button[aria-label="Close"] .${Classes.ICON}{
          background-color: ${({ theme }) => theme.iconColor};
          background-image: none;
          mask-image: ${`url('data:image/svg+xml,${STATIC_ICON.CrossSmall}')`};
          svg {
            visibility: hidden;
          }
        }
    }
  }



  #root {
    width: 100%;
    height: 100%;
  }
`;
